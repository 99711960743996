import React, { useEffect, useRef, useState } from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import { Link as GatsbyLink, useStaticQuery, graphql } from 'gatsby';
import tw from 'twin.macro';
import Link from './BulmaLink';

const Links = [
  {
    title: 'Ziele',
    url: '/ziele',
  },
  {
    title: 'Aktivitäten',
    url: '/aktivitaeten',
  },
  {
    title: 'Premium',
    url: '/reisen',
  },
  {
    title: 'Corona',
    url: '/corona',
  },
  {
    title: 'Über uns',
    url: '/about',
  },
  {
    title: 'Kontakt',
    url: '/kontakt',
  },
];

export default () => {
  const [state, toggle] = useState(false);
  const ref = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        !state ||
        !!event.target.parentElement.dataset.burger ||
        !!event.target.dataset.burger
      )
        return;
      if (!ref.current?.contains(event.target)) {
        toggle(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [state]);
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "nav.png" }) {
        childImageSharp {
          fixed(width: 200) {
            src
          }
        }
      }
    }
  `);
  return (
    <nav css={tw`relative font-sans text-gray-500 sm:shadow-sm`}>
      <div css={tw`px-2 mx-3 xl:mx-8 sm:px-6 xl:px-8`}>
        <div css={tw`relative flex items-center justify-between h-12`}>
          <GatsbyLink to="/">
            <div css={tw`flex items-center`}>
              <img
                css={tw`block h-auto w-36 xl:hidden`}
                src={data.file.childImageSharp.fixed.src}
                alt="Workflow"
              />
              <img
                css={tw`hidden w-64 h-auto xl:block`}
                style={{ maxWidth: '200px' }}
                src={data.file.childImageSharp.fixed.src}
                alt="Workflow"
              />
            </div>
          </GatsbyLink>
          <div
            css={tw`flex items-center justify-center sm:items-stretch sm:justify-start`}
          >
            <div css={tw`hidden sm:block sm:ml-6`}>
              <div css={tw`flex space-x-4`}>
                {Links.map(link => (
                  <Link
                    key={link.url}
                    css={[
                      tw`px-3 py-2 font-medium rounded-md hover:text-brand-orange`,
                      link.title === 'Corona' &&
                        tw`text-red-600 hover:text-red-700`,
                    ]}
                    to={link.url}
                  >
                    {link.title}
                  </Link>
                ))}
              </div>
            </div>
          </div>
          <div
            css={tw`items-center hidden sm:flex`}
            role="button"
            onClick={() => {
              window.open(`https://api.whatsapp.com/send?phone=+4915254039933`);
            }}
          >
            <span css={tw`mr-1 text-green-400`}>
              <FaWhatsapp />
            </span>{' '}
            {` `} 01525/4039933
          </div>
          <div
            css={tw`absolute inset-y-0 right-0 flex items-center pr-2 sm:hidden sm:static sm:inset-auto sm:ml-6 sm:pr-0`}
          >
            <button
              data-burger="1"
              type="button"
              css={tw`inline-flex items-center justify-center p-2 text-gray-400 rounded-md hover:text-white hover:bg-brand-orange focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white`}
              aria-expanded="false"
              onClick={() => toggle(!state)}
            >
              <span css={tw`sr-only`}>Open main menu</span>
              <svg
                css={tw`block w-6 h-6`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>

              <svg
                css={tw`hidden w-6 h-6`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      {state && (
        <div
          css={tw`absolute z-10 block w-screen bg-white shadow-sm sm:hidden top-12`}
          ref={ref}
        >
          <div css={tw`px-2 pt-2 pb-3 space-y-1`}>
            {Links.map(link => (
              <Link
                key={link.url}
                css={tw`block px-3 py-2 text-base font-medium rounded-md hover:bg-brand-orange`}
                to={link.url}
              >
                {link.title}
              </Link>
            ))}
            <div
              css={tw`flex items-center px-3 py-2`}
              role="button"
              onClick={() => {
                window.open(
                  `https://api.whatsapp.com/send?phone=+4915254039933`
                );
              }}
            >
              <span css={tw`mr-1 text-green-400`}>
                <FaWhatsapp />
              </span>{' '}
              {` `} 01525/4039933
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};
